import Acura from './manufacturers/1.png';
import AlfaRomeo from './manufacturers/2.png';
import Audi from './manufacturers/3.png';
import Bmw from './manufacturers/4.png';
import Buick from './manufacturers/5.png';
import Cadillac from './manufacturers/6.png';
import Chevrolet from './manufacturers/7.png';
import Chrysler from './manufacturers/9.png';
import Dodge from './manufacturers/11.png';
import Fiat from './manufacturers/13.png';
import Ford from './manufacturers/14.png';
import FreightlinerLightDuty from './manufacturers/16.png';
import Genesis from './manufacturers/17.png';
import GmcLightDuty from './manufacturers/18.png';
import Honda from './manufacturers/19.png';
import Hyundai from './manufacturers/21.png';
import Infiniti from './manufacturers/22.png';
import Jaguar from './manufacturers/24.png';
import Jeep from './manufacturers/25.png';
import Kia from './manufacturers/26.png';
import LandRover from './manufacturers/27.png';
import Lexus from './manufacturers/28.png';
import Lincoln from './manufacturers/29.png';
import Mazda from './manufacturers/30.png';
import MercedesBenz from './manufacturers/31.png';
import Mini from './manufacturers/33.png';
import Mitsubishi from './manufacturers/34.png';
import Nissan from './manufacturers/35.png';
import Porsche from './manufacturers/39.png';
import Scion from './manufacturers/42.png';
import Smart from './manufacturers/43.png';
import Subaru from './manufacturers/44.png';
import Tesla from './manufacturers/46.png';
import Toyota from './manufacturers/47.png';
import Volkswagen from './manufacturers/48.png';
import Volvo from './manufacturers/49.png';

export {
  Acura,
  AlfaRomeo,
  Audi,
  Bmw,
  Buick,
  Cadillac,
  Chevrolet,
  Chrysler,
  Dodge,
  Fiat,
  Ford,
  FreightlinerLightDuty,
  Genesis,
  GmcLightDuty,
  Honda,
  Hyundai,
  Infiniti,
  Jaguar,
  Jeep,
  Kia,
  LandRover,
  Lexus,
  Lincoln,
  Mini,
  Mazda,
  MercedesBenz,
  Mitsubishi,
  Nissan,
  Porsche,
  Scion,
  Smart,
  Subaru,
  Tesla,
  Toyota,
  Volkswagen,
  Volvo
};
